<template>
  <div class="covid-19">
      <div class="covid-19-information">We are carefully monitoring the evolving situation surrounding COVID. Please be advised that all wedding events will be held in accordance with CDC guidelines and that event regulations are subject to change without notice to ensure the health and safety of every attendee.</div>
      <div class="covid-19-information">We respectfully request everyone attending do their best to get tested for COVID at least 48 hours before the wedding. Regardless of the test results, if you have any symptoms, please excuse yourself from attending.</div>
  </div>
</template>

<script>
export default {
  name: "Covid"
};
</script>

<style scoped>

.covid-19-information {
  font-size: 18px;
  font-style: italic;
  padding: 5px 10%;
}

</style>