<template>
  <div class="wedding-party">
    <div class="row title-row">
      <div class="column">
        <div>Maid of Honor</div>
      </div>
      <div class="column">
        <div>Best Man</div>
      </div>
    </div>
    <div class="row">
      <div class="column">
        <WeddingPartyMOH name="Eimi Satoh" description="A lifelong New Jerseyan and a nomad on her 4th country of residence walk into a... school, because they're studious 13-year olds and not of legal drinking age. This joke doesn't end the way it usually would, and this friendship doesn't start conventionally either." descriptionTwo="Eimi and Christina met as 8th graders in the hallowed halls of Harrington Park School sometime before 8 am in the morning. To Christina, Eimi was the new kid from Japan who she had befriended. To Eimi, Christina's friendliness was plain confusing (even more so because Eimi is not a morning person, and remember-- it was before 8 am). But when Christina gave Eimi a most American homecoming and quite the culture shock by introducing her to Rice-A-Roni, backyard hot tubs, and the proper structure of a 5-paragraph essay, Eimi knew Christina was the real deal." descriptionThree="The complementarity of Christina's kindness and Eimi's frankness has sustained their friendship over the years. Christina's tendency to see the glass half full and Eimi's inclination to see the contents of the glass as nothing more than water have together grounded the two's shared experiences as friends and their personal narratives as individuals in multi-faceted ways. Though Eimi's candor has landed her in hot water at times, it's exactly why she'll be working to keep all the important bits and pieces running smoothly as maid of honor. She will be taking occasional breaks from her duties to break it down on the dance floor." filename="Eimi.jpeg"/>
      </div>
      <div class="column">
        <WeddingPartyBM name="Nicholas Ferris" description="Nicholas is Thomas's younger brother. Growing up, and possibly to this day, Nicholas has been the biggest nuisance to Thomas. He still gets a kick out of pushing one too many of Thomas's buttons, but with that being said, Nicholas appreciates everything about Thomas. Growing up, Thomas was an amazing role model and someone Nicholas still looks up to today. Thomas has gifted Nicholas with some of his most cherished memories. From making silly faces at Thomas from across a restaurant table in their younger days to spending way too many hours in a row playing video games until they were both purple with laughter, Nicholas can not think of time that Thomas has not brightened his day." descriptionTwo="Beyond all of the smiles that Thomas has brought to Nicholas's face, his impact goes much deeper. Thomas has been an unfaltering pillar of support for Nicholas no matter what the situation has been. Thomas is someone that Nicholas repeatedly counts on to be there for him and Thomas is without fail. Nicholas is eternally grateful for all Thomas has done for him and hopes he can pay a fraction of it back by being the best best man for his brother who he loves. Nicholas looks forward to Christina joining the family, which at this point is fairly redundant. He believes that Christina brings out the best in his brother and it makes Nicholas extremely happy to see how happy she makes Thomas. Nicholas loves them both very much and can't wait to embarrass himself on the dance floor of their wedding. For anyone who knows Nicholas, you know he hates dancing, but if this is not a special enough occasion he do not know what is." filename="nicholas.jpg"/>
      </div>
    </div>
    <div class="row title-row title-row-two">
      <div class="column">
        <div>Bridal Party</div>
      </div>
      <div class="column">
        <div>Grooms Party</div>
      </div>
    </div>
    <div class="row">
      <div class="column">
        <WeddingPartyMember name="Aster Schmidt" description="Aster first met Christina as her girlfriend's hallmate's roommate. After noticing Chris's replica portal gun, Aster knew she was a total nerd. Little did she know that they would live together the next year in the Music Appreciation House and grow to be lifelong friends, watching anime and viewing sunrises from the wrong cardinal direction. Thomas was a fellow fris-bro who Aster got to know better through driving him to tournaments and playing webb, moose, and nannerball. When Aster saw her two friends junior year trying to one-up one another in Super Mario Sunshine, she knew they were meant to be, and were ecstatic when two of her favorite people started dating. Whether playing table top RPGs or shotgunning in shark showers with the two, Aster is very happy to see her good friends get married, and hopes for the best in all their future endeavors (that don't involve Mario Party)." filename="aster.jpg"/>
      </div>
      <div class="column">
        <WeddingPartyMemberAlt name="Andrew Hutcheson" description="Hutch first met Christina through being in the same incoming group of freshmen for band camp. While not interacting much during band camp, the two both were wearing matching plaid shirts at their first midnight kickball and their friendship was set in stone. Little did Hutch or Tom know at the time, the two were in the same freshman orientation group at college. Tom would become a minor plot point in Hutch's junior year when he decided to actively participate in pep band for basketball. The minor subplot eventually expanded into the major storyline that was the year all three got together regularly to watch anime when Hutch lived near them in Massachusetts." filename="hutch.jpg"/>
      </div>
    </div>
    <div class="row">
      <div class="column">
        <WeddingPartyMemberAlt name="Cassie Smith" description="When Cassie started at ClearCompany right out of college a few years ago, she had no idea that her quirky desk neighbor, Chris, would become one her closest friends. Where Chris is open and friendly, Cassie is more reserved and quiet, so it took some time to really get to know each other. However, after many discussions of work gossip, sharing each other's weeby interests, and getting forced to attend a party at a mostly empty apartment, the friendship was set and there was no going back." filename="cassie.jpg"/>
      </div>
      <div class="column">
        <WeddingPartyMember name="Doug 'Tugs' Caristo" description="Christina, Pud—Tom, and Doug (pictured with his girlfriend Marissa) have been friends for over five years, and Doug cannot believe how the time has flown. Their friendship bloomed after meeting the two during their junior year at Lehigh University. From nights out on the town, travelling the world, high-fantasy RPG games, or even just hanging around one of their living rooms, one would be hard-pressed to find a way not to enjoy the company of the three. Doug cannot wait to see what the future brings for the newly-weds-to-be and look forward to celebrating their relationship taking the next step!" filename="doug.jpeg"/>
      </div>
    </div>
    <div class="row">
      <div class="column">
        <WeddingPartyMember name="Caitlyn Rogalsky" description="Caitlyn and Christina are more than friends, they are brothers. Literally. They met through the Marching 97 at Lehigh University and grew to be friends when paired as big/little in Kappa Kappa Psi. They continued to spend time together outside of band and have stayed close friends after graduating college. Christina was a member of Caitlyn's bridal party in the summer of 2020, and Caitlyn is excited to now also be a part of Christina and Tom's wedding party." filename="caitlyn.jpg"/>
      </div>
      <div class="column">
        <WeddingPartyMemberAlt name="Myles Ferris" description="Myles Ferris is Thomas' favorite cousin. Myles has shared his whole life with Thomas since the day he was born. Thomas would spend endless hours at Myles' house playing video games, man hunt, and enjoying delicious BBQs. From the beginning of these shared memories and special moments Myles has always looked up to Thomas. He has been an exemplary role model and provided a perfect path to follow. To this day Thomas makes time to put a smile on Myles' face as well as offer guidance and advice while doing so. Thomas has been a cornerstone in Myles life from the moment his eyes opened and Myles knows he will continue to be there for him as time goes on. Only having met Christina a few times, Myles knew from the jump that it was a perfect match. Christina joins in the laughter and family moments when she hops in discord to laugh along with us. Myles looks forward to sharing more of these moment and continuing to welcome Christina into the family with open arms." filename="myles.jpeg"/>
      </div>
    </div>
    <div class="row">
      <div class="column">
        <WeddingPartyMemberAlt name="CJ Walker" description="CJ and Chris have known each other since they were 5, which means that their friendship reached the legal drinking age this year. Through shared family vacations and swapping manga in middle school to trading rides to marching band in high school, Chris is still the first person CJ texts with news from home or a new anime recommendation." filename="cj.jpg"/>
      </div>
      <div class="column">
        <WeddingPartyMember name="Evan 'JP' Gainza" description="Evan first met Tom through the Lehigh Ultimate Frisbee team; however it wasn't until Jonathan brought Tom over to hang out in a friend's Trembly apartment that Evan got to know him. As with Tom, Evan was introduced to Christina through mutual friends. The friendship developed after graduation when Christina, Tom and Evan became house mates up in Boston. Over the past three years, their friendship has grown and deepened, from their traveling misadventures to playing complex board games and watching Magical Girl anime. Evan is now excited to see how the next phase of Tom's and Christina's relationship develops as they embark on married life!" filename="jp.png"/>
      </div>
    </div>
    <div class="row">
      <div class="column">
        <WeddingPartyMember name="Danielle Schiraldi" description="Christina and Danielle met Freshman year at Lehigh University. Danielle remembers the first conversation they had; it was about Donnie Darko and anime. That one conversation slowly turned into more and more and blossomed into an incredible friendship full of laughs, honesty and road trips to New Jersey. The moment Danielle knew she had a lifelong friend was when the day after complaining she was hot, Christina acquired an AC seemingly by magic and shouted across the street, 'I found you an AC!' Danielle is blessed to have such a caring, compassionate and fun friend in her life and is thrilled to be sharing this experience with her." filename="danielle.jpg"/>
      </div>
      <div class="column">
        <WeddingPartyMemberAlt name="Jonathan 'Squeegee' Chisholm" description="Jonathan met both Christina and Tom during their time together at Lehigh University. An ultimate frisbee teammate of Tom's, he was lucky enough to be present when the lucky couple first met each other. A steadfast friend to each individually and self-proclaimed number one fan of them as a couple, Jonathan lived in Boston with Christina and Tom for their first three years in the city. He looks forward to meeting (and tossing one back with) more friends and family of the bride and groom." filename="jonathan.jpeg"/>
      </div>
    </div>
    <div class="row">
      <div class="column">
        <WeddingPartyMemberAlt name="Jenn Bateman" description="Jenn met Christina freshman year at a party when Christina was introduced as ‘Crazy Russian Chick’. Jenn and Christina’s paths didn’t cross much until Jenn sat with Christina in Calc 1 their junior year and said, ‘Hey!! You’re that Crazy Russian Chick!!’ Little did Jenn know that she was not Russian, but she was crazy (in all the best possible ways), and little did Jenn know that would be the start of an amazing friendship. As the typical poor college kid, Jenn did not want to pay to do laundry in her apartment so Christina graciously allowed Jenn to do it at her house while they studied for calculus. Christina helped Jenn pass the class, and Jenn helped Christina pass biology core III in turn. Over the years, these two grew close as Christina became part of the 457 family, a seamless transition that also involved Thomas. Christina and Jenn became important parts of each other’s lives, helping one another through the many curve balls the game of life has thrown their way. What began as friendly gestures grew into a wonderful friendship that will last a lifetime. Game on." filename="jenn.png"/>
      </div>
      <div class="column">
        <WeddingPartyMember name="Mike Medica" description="Mike (pictured with his girlfriend Sam, known as Medica, Meds, Med, and any other variant on 'Medical') met Christina in high school at NVOT. Though 3 years her senior (but who's counting?), the two became fast friends and quickly bonded over being general goofballs and going to concerts (with the typical threats to his wellbeing from the father of the bride-to-be). He met Tom a few years later on a trip to Lehigh and soon saw the love the lucky couple still shares to this day. He is excited to take part in such a momentous occasion for Christina and Tom!" filename="medica.png"/>
      </div>
    </div>
    <div class="row">
      <div class="column">
        <WeddingPartyMember name="Jessica Lloyd" description="Jess and Christina first met on the way to band camp the beginning of their freshman year at Lehigh. Jess was super nervous because she didn’t know anyone, but upon meeting Christina she knew she had a lifelong friend. Through their many adventures together, including battling mice invasions and escaping a snowed-in cabin, they’ve learned that they’ll always have each other’s backs. Jess couldn’t be more excited to be a part of this next stage in Christina’s life!" filename="jess.jpeg"/>
      </div>
      <div class="column">
        <WeddingPartyMemberAlt name="Neil Petersen" description="Neil met the bride and groom through coming to Lehigh University to party with a mutual friend. Their relationship started with games (both drinking and video) and has culminated in them becoming neighbors in Somerville, band mates, golfing buddies and most of all, friends. Neil and Tom have bonded over their mutual activities and being chaos partners while he and Christina are both 'people of action', and these similarities have made his relationships between both bride and groom close and extremely special." filename="neil.png"/>
      </div>
    </div>
    <div class="row">
      <div class="column">
        <WeddingPartyMemberAlt name="Katherine" description="Katherine and Christina met at Lehigh University when they both started the Marching 97 as freshman (and later, both joined Kappa Kappa Psi the same year). They shared a room and bunk beds their sophomore year at the Music Appreciation House, and had several classes together in later years. It was very exciting when Christina moved to Boston because that meant they could bake cookies together! Katherine thinks of Christina as the big sister she never had." filename="katherine.jpg"/>
      </div>
    </div>
    <div class="row">
      <div class="column">
        <WeddingPartyMember name="Seneca Rasey" description="The universe brought Christina and Seneca together freshman year of college, living down the hall and around the corner from each other in Lehigh's Lower Cents dorms. Bonding over their love of all things marching band and movie marathons featuring the unparalleled chemistry of Drew Berrymore & Adam Sandler (like seriously, why is no one talking about this), they became fast friends. Two years of living together, a literal brotherhood (shoutouts to KKPsi), plus countless adventures and heart-to-hearts – not to mention their recent discovery of both being triple water signs – cemented this friendship for life." filename="seneca.png"/>
      </div>
    </div>
    <div class="row">
      <div class="column">
        <WeddingPartyMemberAlt name="Trisha Arora" description="After a series of silly first impressions, Trisha met Jonathan’s rambunctious and ridiculous roommates after they started dating. The group podded together during the 2020 Global Pandemic, and got to make up for lost time of not knowing one other by growing exponentially closer with every passing weekend. Trisha and Christina bonded over shared experiences and their goofy demeanors whereas Trisha and Thomas bonded over their shared love for the artist Pitbull. From trips to Puerto Rico to adventures around New England, their friendship felt so easy and natural that seeing them you’d never guess that they have only known each other for a year and a half. Trisha feels incredibly fortunate and lucky to have had such supportive friends in Christina and Puddles and equally as honored to be a part of the bridal group." filename="trisha.jpg"/>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import WeddingPartyMember from "@/components/WeddingPartyMember.vue";
import WeddingPartyMemberAlt from "@/components/WeddingPartyMemberAlt.vue";
import WeddingPartyBM from "@/components/WeddingPartyBM.vue";
import WeddingPartyMOH from "@/components/WeddingPartyMOH.vue";

export default {
  name: "Home",
  components: {
    WeddingPartyMember,
    WeddingPartyMemberAlt,
    WeddingPartyBM,
    WeddingPartyMOH
  }
};
</script>

<style>

.wedding-party { 
  background-color:rgba(255, 255, 255, 0.80);
}

.title-row {
  font-size: 30px;
  font-style: italic;
}

.title-row-two {
  padding-top: 30px;
}

.column {
  float: left;
  width: 50%;
}

.image-column {
  width: 25%;
}

.image-column-alt {
  width: 25%;
}

.text-column {
  width: 75%;
  text-align: right;
}

.text-column-alt {
  width: 75%;
  text-align: left;
}

.row:after {
  content: "";
  display: table;
  clear: both;
}

.wedding-party-member {
  padding: 10px;
}

.wedding-party-member-image {
  height: 125px;
  width: 125px;
  margin-top: 14px;
  border-radius: 20px 20px;
}

.wedding-party-member-name {
  font-size: 22px;
  padding-bottom: 4px;
}

.wedding-party-member-description {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  padding-left: 15%;
}

.wedding-party-member-description-alt {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
    padding-right: 15%;
}

.also-including-column {
  font-style: italic;
  text-align: left;
}
</style>