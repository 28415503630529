<template>
  <div class="about">
    <img class="about-paragraph-image img-two-third" src="@/assets/chrisAndPud/weddingDate.jpg"/>
    <img class="about-paragraph-image img-square" src="@/assets/chrisAndPud/pr1.jpg"/>
    <img class="about-paragraph-image img-two-third-side" src="@/assets/chrisAndPud/halloween.jpg"/>
    <img class="about-paragraph-image img-square" src="@/assets/chrisAndPud/capeMayDinner.jpg"/>
    <div class="about-paragraph">The story of how Christina and Tom met varies depending on who is telling it. However, the gist of it is that the two of them were at a party together the end of their first semester junior year. Christina was struggling to beat a level in Super Mario Sunshine, and Tom stepped in to offer his expertise on spin-jumps. After spending winter break playing online games together alongside their friends Jonathan 'Squeegee' and Doug 'Tugs', the two had their next hang out when Christina picked Tom up from the airport to head to Lehigh.</div>
    <div class="about-paragraph">Tom introduced Christina officially to his group of friends, many of whom Christina was previously acquainted. After a few hang-outs, the two confessed their feelings for one another. Many a time was spent eating Hawaiian pizza, playing SSX Tricky, and overall enjoying each other's company. Soon the semester came to a close, and the two both got internships over the summer by Lehigh. Thinking it was silly to pay rent twice while wanting to spend as much time as possible together, the two decided to live together for the summer after six months of knowing each other. The deal worked out spendidly, with the two making lunches together in the mornings and spending the evenings playing Pokemon Go, while enjoying weekends of Beat the Clock beer deals, bowling, and tubing down the Lehigh river.</div>
    <img class="about-paragraph-image img-square" src="@/assets/chrisAndPud/noseKiss.jpg"/>
    <img class="about-paragraph-image img-two-third" src="@/assets/chrisAndPud/collegeDate.jpg"/>
    <img class="about-paragraph-image img-square" src="@/assets/chrisAndPud/acousticsInApril.jpg"/>
    <div class="about-paragraph">Soon senior year began. The couple attempted to try each other's extracurricular activites. While Ultimate Frisbee didn't stick for Christina, Tom decided to join marching band his final year of college after the men's Frisbee team had to take a semester off. Many an evening were spent at Sotto's trivia, or with Mario Golf - Toadstool Tour, or playing their first ever Dungeons and Dragons campaign.</div>
    <div class="about-paragraph">With the stress of the end of their college career weighing them down, the two took a break, but remained close friends. A fantasy football league got the two talking regularly once school was done. Soon Tom was showing Christina new albums and podcasts, and Christina picked out television shows to watch over Discord together. On February 4th, 2018, the two confessed they were still in love with each other, and despite living over 1,000 miles apart, they decided to get back together. </div>
    <div class="about-paragraph">Long distance was hard, with the two only seeing one another once a month. However, it did not last long. Christina and Tom had a plan to move to Boston with their friends Jonathan and Evan 'JP' once at least of them had secured a job. That happened fairly quickly, as Christina was offered a position at ClearCompany (where she still works!) in May of the same year. The two made their plans, and in late June of 2018, Christina took a one-way ticket to Florida with the intent on the two of them driving up to Boston together. They spent three days on the road, seeing Charleston, South of the Border, and Alec 'Gus' in Maryland along the way. Finally, with the help of Jonathan, the two moved into their first apartment in the greater Boston area!</div>
    <img class="about-paragraph-image img-square" src="@/assets/chrisAndPud/floridaWeddingDate.jpg"/>
    <img class="about-paragraph-image img-two-third" src="@/assets/chrisAndPud/florida.jpg"/>
    <img class="about-paragraph-image img-square" src="@/assets/chrisAndPud/birthdayDinner.jpg"/>
    <div class="about-paragraph">Two months later in September, Christina and Tom moved in with Jonathan and Evan, and upgraded a year later to a bigger place closer to trains and nightlife. The four friends do a lot together, including board games, dungeons and dragons, and when time and quarantine allow it, beer gardens and breweries, karaoke, and relaxing getaways. After three wonderful years with the crew, Thomas and Christina decided to get their own apartment September of 2021. While the two of them still see their old roommates frequently, the couple makes sure to have time to just the two of them, and their romance has evolved into an incredibly loving, supportive, and silly partnership.</div>
    <div class="about-paragraph">On Christmas Day at midnight, Tom surprised Christina by driving through a noreaster to appear at her parent's front door. He wanted to be the first to wish her a merry Christmas, and did so with a ring. Christina was both surprised and elated. The two have spent the past few months planning, and are excited for not only the upcoming wedding, but also to spend the rest of their lives together.</div>
    <img class="about-paragraph-image img-square" src="@/assets/chrisAndPud/areYouReadyForSomeFootball.jpg"/>
    <img class="about-paragraph-image img-two-third" src="@/assets/chrisAndPud/capeMay.jpg"/>
    <img class="about-paragraph-image img-square" src="@/assets/chrisAndPud/derby.jpg"/>
    <img class="about-paragraph-image img-two-third" src="@/assets/chrisAndPud/hawaiian.jpg"/>
  </div>
</template>

<script>

export default {
  name: "Home"
};
</script>

<style scoped>
  .about {
    background-color:rgba(255, 255, 255, 0.80);
  }

  .about-paragraph {
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    padding: 6px 10%;
    text-align: center;
  }

  .about-paragraph-image {
    margin: 10px;
    padding: 3px;
    border-radius: 20px 20px;
  }

  .img-two-third {
    height: 225px;
    width: 150px;
    border: 5px inset rgb(159,216,199);
  }

  .img-square {
    height: 225px;
    width: 225px;
    border: 5px outset rgb(159,216,199);
  }

  .img-two-third-side {
    height: 225px;
    width: 335px;
    border: 5px inset rgb(159,216,199);
  }
</style>